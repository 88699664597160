

































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import { validationStateMixin } from '@/util/validationState'
import floorToCents from '@/util/floorToCents'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import User from '@/models/User'
import StripeCard from '@/models/StripeCard'
import StripeBankAccount from '@/models/StripeBankAccount'
import CurrencyInput from '@/components/inputs/CurrencyInput.vue'
import PaymentTypeImage from '@/components/PaymentTypeImage.vue'
import PaymentMethodInfo from '@/components/PaymentMethodInfo.vue'
import ManualPayment from '@/models/ManualPayment'

@Component({
  components: {
    Modal,
    CurrencyInput,
    PaymentTypeImage,
    PaymentMethodInfo,
  },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('paymentMethods', ['fsaHsa', 'nonFsaHsa']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  validations: {
    nonFsaHsaPaymentAmount: {
      required,
      minValue: minValue(0),
      accountMax: (value, vm) => value <= vm.currentBalance,
    },
    fsaHsaPaymentAmount: {
      required,
      minValue: minValue(0),
      accountMaxFsa: (value, vm) => value <= vm.fsaBalance,
    },
    totalPaymentAmount: {
      accountMax: (value, vm) => value <= vm.currentBalance,
    },
  },
})
export default class MakeMultiPaymentModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  me!: User
  nonFsaHsa!: (StripeCard | StripeBankAccount)[]
  fsaHsa!: StripeCard[]

  nonFsaHsaPaymentAmount: number = 0
  nonFsaHsaPaymentMethodId: string = ''

  fsaHsaPaymentAmount: number = 0
  fsaHsaPaymentMethodId: string = ''
  error: string = ''

  get hasFsaHsaBalance() {
    return Number(this.me.account?.fsaEligibleBalance) > 0
  }

  get fsaBalance() {
    return Number(this.me.account?.fsaEligibleBalance) || 0
  }

  get fsaBalanceFormatted() {
    return `$${this.fsaBalance.toFixed(2)}`
  }

  get currentBalance(): number {
    if (!this.me.isOnOrdersBilling) {
      return Number(this.me.account?.currentBalance) || 0
    }
    return Number(this.me.account?.currentOwedBalance) || 0
  }

  get totalPaymentAmount() {
    const fsaHsa: number = this.fsaHsaPaymentAmount
    const nonFsaHsa: number = this.nonFsaHsaPaymentAmount

    return floorToCents(fsaHsa + nonFsaHsa)
  }

  get submitDisabled() {
    return (
      this.$v.$invalid ||
      this.isInProgress('paymentMethods/makePayment') ||
      this.isInProgress('user/makeManualPartialPayment')
    )
  }

  get nonFsaHsaPaymentMethodOptions() {
    return this.nonFsaHsa.map(paymentMethod => {
      const paymentType = paymentMethod instanceof StripeCard ? 'Credit Card' : 'Bank Account'
      const brand =
        paymentMethod instanceof StripeCard
          ? paymentMethod.brand[0].toUpperCase() + paymentMethod.brand.slice(1)
          : ''

      const text = [brand, paymentType, '····', paymentMethod.lastFour].filter(Boolean).join(' ')

      return {
        value: paymentMethod.id,
        text,
      }
    })
  }

  get fsaHsaPaymentMethodOptions() {
    return this.fsaHsa.map(paymentMethod => {
      const brand = paymentMethod.brand[0].toUpperCase() + paymentMethod.brand.slice(1)

      const text = [brand, 'Credit Card', '····', paymentMethod.lastFour].filter(Boolean).join(' ')

      return {
        value: paymentMethod.id,
        text,
      }
    })
  }

  get loading() {
    return (
      this.isInProgress('user/loadMe') ||
      this.isInProgress('paymentMethods/load') ||
      this.isInProgress('paymentMethods/makePayment') ||
      this.isInProgress('user/makeManualPartialPayment')
    )
  }

  onShown() {
    this.nonFsaHsaPaymentAmount = Number(this.me.account?.nonFsaEligBalance) || 0
    this.fsaHsaPaymentAmount = Number(this.me.account?.fsaEligibleBalance) || 0

    this.setDefaultPaymentMethods()
  }

  setDefaultPaymentMethods() {
    if (this.nonFsaHsa && this.nonFsaHsa.length) {
      this.nonFsaHsaPaymentMethodId = this.nonFsaHsa[0].id
    }

    if (this.fsaHsa && this.fsaHsa.length) {
      this.fsaHsaPaymentMethodId = this.fsaHsa[0].id
    }
  }

  async makePayment() {
    this.$v.$touch()
    if (this.$v.$invalid) return

    if (this.me.cohorts?.equiscript) {
      await this.makePaymentEquiscript()
    } else {
      await this.makePaymentDefault()
    }

    const err =
      this.getError('paymentMethods/makePayment') || this.getError('user/makeManualPartialPayment')
    if (err) {
      this.error = err.data
      return
    } else {
      this.error = ''
    }

    this.$store.dispatch('showBanner', {
      id: 'payment-submitted-success',
      bgVariant: 'success',
      title: this.$t('Payment submitted'),
      message: this.$t('account.paymentSubmitted.message', { email: this.me.email }),
      durationMs: 5000,
    })

    this.close()
  }

  async makePaymentDefault() {
    const nonFsaPaymentMethod = this.nonFsaHsa.find(
      paymentMethod => paymentMethod.id === this.nonFsaHsaPaymentMethodId,
    )

    await this.$store.dispatch('paymentMethods/makePayment', {
      paymentMethodId: this.fsaHsaPaymentMethodId,
      paymentMethodClass: 'StripeCard',
      amount: this.fsaHsaPaymentAmount.toFixed(2),
    })
    await this.$store.dispatch('paymentMethods/makePayment', {
      paymentMethodId: this.nonFsaHsaPaymentMethodId,
      paymentMethodClass:
        nonFsaPaymentMethod instanceof StripeCard ? 'StripeCard' : 'StripeBankAccount',
      amount: this.nonFsaHsaPaymentAmount.toFixed(2),
    })
  }

  async makePaymentEquiscript() {
    const fsaPayment = new ManualPayment()
    fsaPayment.paymentMethodId = this.fsaHsaPaymentMethodId
    fsaPayment.paymentMethodClass = 'StripeCard'
    fsaPayment.amount = Number(this.fsaHsaPaymentAmount)

    const nonFsaPayment = new ManualPayment()
    const nonFsaPaymentMethod = this.nonFsaHsa.find(
      paymentMethod => paymentMethod.id === this.nonFsaHsaPaymentMethodId,
    )
    nonFsaPayment.paymentMethodId = this.fsaHsaPaymentMethodId
    nonFsaPayment.paymentMethodClass =
      nonFsaPaymentMethod instanceof StripeCard ? 'StripeCard' : 'StripeBankAccount'
    nonFsaPayment.amount = Number(this.nonFsaHsaPaymentAmount)

    const payments = [fsaPayment, nonFsaPayment].filter(payment => payment.amount > 0)

    this.$store.dispatch('user/makeManualPartialPayment', {
      payments,
    })
  }

  close() {
    this.$nextTick(() => {
      this.$bvModal.hide('make-multi-payment-modal')
    })
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.nonFsaHsaPaymentAmount = 0
    this.nonFsaHsaPaymentMethodId = ''

    this.fsaHsaPaymentAmount = 0
    this.fsaHsaPaymentMethodId = ''

    this.error = ''
    this.$v.$reset()
  }
}
