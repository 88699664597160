






























































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import OrderPreferences from '@/models/OrderPreferences'
import Address from '@/components/Address.vue'
import Card from '@/components/Card.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import SkeletonLine from '@/components/SkeletonLine.vue'
import PaymentTypeImage from '@/components/PaymentTypeImage.vue'
import PaymentMethodInfo from '@/components/PaymentMethodInfo.vue'
import User from '@/models/User'

@Component({
  components: {
    Address,
    Card,
    LinkArrowIcon,
    SkeletonLine,
    PaymentTypeImage,
    PaymentMethodInfo,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('asyncStatus', ['getError']),
  },
})
export default class OrderPreferencesCard extends Vue {
  me!: User

  getError!: (key: string) => Error

  get preferences(): OrderPreferences {
    return this.$store.state.orders.preferences
  }

  get hasError(): boolean {
    return !this.preferences && !!this.getError('orders/loadPreferences')
  }

  get billingSchedule() {
    if (this.preferences.monthlyBillingDay) {
      return this.$t('Monthly on the {dayOfMonth}', {
        dayOfMonth: this.preferences.monthlyBillingDay,
      })
    } else {
      return this.$t('When your medications ship')
    }
  }

  get hasAnnualBills(): boolean {
    return !!(this.preferences.annualBills && this.preferences.annualBills.length)
  }
}
