



























































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import { validationStateMixin } from '@/util/validationState'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import User from '@/models/User'
import StripeCard from '@/models/StripeCard'
import StripeBankAccount from '@/models/StripeBankAccount'
import CurrencyInput from '@/components/inputs/CurrencyInput.vue'
import PaymentTypeImage from '@/components/PaymentTypeImage.vue'
import PaymentMethodInfo from '@/components/PaymentMethodInfo.vue'
import ManualPayment from '@/models/ManualPayment'

@Component({
  components: {
    Modal,
    CurrencyInput,
    PaymentTypeImage,
    PaymentMethodInfo,
  },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('paymentMethods', ['fsaHsa', 'nonFsaHsa']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  validations: {
    paymentAmount: {
      required,
      minValue: minValue(0),
      accountMax: (value, vm) => value <= vm.currentBalance,
    },
  },
})
export default class MakePaymentModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  me!: User
  nonFsaHsa!: (StripeCard | StripeBankAccount)[]
  fsaHsa!: StripeCard[]

  paymentAmount: number = 0
  selectedPaymentMethodId: string = ''
  error: string = ''

  selectPaymentMethod(paymentMethodId: string) {
    this.selectedPaymentMethodId = paymentMethodId
  }

  get paymentMethods() {
    if (this.me.account?.fsaEligibleBalance) {
      const defaultFsaHsa = this.fsaHsa.find(
        paymentMethod => paymentMethod.defaultFsaHsaPaymentMethod,
      )
      const otherFsaHsa = this.fsaHsa.filter(
        paymentMethod => paymentMethod.id !== defaultFsaHsa?.id,
      )
      return [defaultFsaHsa, ...otherFsaHsa, ...this.nonFsaHsa].filter(Boolean) as (
        | StripeCard
        | StripeBankAccount
      )[]
    }

    const defaultPaymentMethod = this.nonFsaHsa.find(
      paymentMethod => paymentMethod.defaultPaymentMethod,
    )
    const otherPaymentMethods = this.nonFsaHsa.filter(
      paymentMethod => paymentMethod.id !== defaultPaymentMethod?.id,
    )
    return [defaultPaymentMethod, ...otherPaymentMethods].filter(Boolean) as (
      | StripeCard
      | StripeBankAccount
    )[]
  }

  get paymentMethodOptions() {
    return this.paymentMethods.map(paymentMethod => {
      const paymentType = paymentMethod instanceof StripeCard ? 'Credit Card' : 'Bank Account'
      const brand =
        paymentMethod instanceof StripeCard
          ? paymentMethod.brand[0].toUpperCase() + paymentMethod.brand.slice(1)
          : ''

      const text = [brand, paymentType, '····', paymentMethod.lastFour].filter(Boolean).join(' ')

      return {
        value: paymentMethod.id,
        text,
      }
    })
  }

  get loading() {
    return this.isInProgress('user/loadMe') || this.isInProgress('paymentMethods/load')
  }

  get currentBalance() {
    return Number(this.me.account?.currentBalance) || 0
  }

  onShown() {
    this.paymentAmount = this.currentBalance

    this.setDefaultPaymentMethod()
  }

  setDefaultPaymentMethod() {
    if (this.paymentMethods && this.paymentMethods.length) {
      this.selectedPaymentMethodId = this.paymentMethods[0].id
    }
  }

  async makePayment() {
    this.$v.$touch()
    if (this.$v.$invalid) return

    const paymentMethod = this.paymentMethods.find(
      paymentMethod => paymentMethod.id === this.selectedPaymentMethodId,
    )
    if (!paymentMethod) return

    if (this.me.cohorts?.equiscript) {
      await this.makePaymentEquiscript()
    } else {
      await this.makePaymentDefault()
    }

    const err =
      this.getError('paymentMethods/makePayment') || this.getError('user/makeManualPartialPayment')
    if (err) {
      this.error = err.data
      return
    }

    this.$store.dispatch('showBanner', {
      id: 'payment-submitted-success',
      bgVariant: 'success',
      title: this.$t('Payment submitted'),
      message: this.$t('account.paymentSubmitted.message', { email: this.me.email }),
      durationMs: 5000,
    })

    this.close()
  }

  async makePaymentDefault() {
    const paymentMethod = this.paymentMethods.find(
      paymentMethod => paymentMethod.id === this.selectedPaymentMethodId,
    )
    const paymentMethodClass =
      paymentMethod instanceof StripeCard ? 'StripeCard' : 'StripeBankAccount'

    await this.$store.dispatch('paymentMethods/makePayment', {
      paymentMethodId: this.selectedPaymentMethodId,
      paymentMethodClass,
      amount: this.paymentAmount.toFixed(2), // expects "12.00"
    })
  }

  async makePaymentEquiscript() {
    const paymentMethod = this.paymentMethods.find(
      paymentMethod => paymentMethod.id === this.selectedPaymentMethodId,
    )
    const paymentMethodClass =
      paymentMethod instanceof StripeCard ? 'StripeCard' : 'StripeBankAccount'

    const payment = new ManualPayment()
    payment.paymentMethodId = this.selectedPaymentMethodId
    payment.paymentMethodClass = paymentMethodClass
    payment.amount = this.paymentAmount

    this.$store.dispatch('user/makeManualPartialPayment', {
      payments: [payment],
    })
  }

  close() {
    this.$nextTick(() => {
      this.$bvModal.hide('make-payment-modal')
    })
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.paymentAmount = 0
    this.selectedPaymentMethodId = ''
    this.error = ''
    this.$v.$reset()
  }
}
