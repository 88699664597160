import { render, staticRenderFns } from "./MakeMultiPaymentModal.vue?vue&type=template&id=56918134&"
import script from "./MakeMultiPaymentModal.vue?vue&type=script&lang=ts&"
export * from "./MakeMultiPaymentModal.vue?vue&type=script&lang=ts&"
import style0 from "./MakeMultiPaymentModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BAlert } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import { BCol } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { BFormInvalidFeedback } from 'bootstrap-vue'
import { BFormRow } from 'bootstrap-vue'
import { BFormSelect } from 'bootstrap-vue'
installComponents(component, {BAlert,BButton,BCol,BForm,BFormGroup,BFormInvalidFeedback,BFormRow,BFormSelect})
