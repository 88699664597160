



































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { formatDateMedium } from '@/i18n'
import User from '@/models/User'
import Card from '@/components/Card.vue'
import { Payment } from '@/ppapi/PPBillingApi'
import PaymentTypeImage from '@/components/PaymentTypeImage.vue'
import PaymentMethodInfo from '@/components/PaymentMethodInfo.vue'
import SkeletonListGroup from '@/components/SkeletonListGroup.vue'
import ShowMoreLink from '@/components/ShowMoreLink.vue'

@Component({
  components: {
    Card,
    PaymentTypeImage,
    PaymentMethodInfo,
    SkeletonListGroup,
    ShowMoreLink,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
    ...mapGetters('orders', ['canShowMoreBillPayments', 'visibleBillPayments']),
  },
})
export default class BillPaymentsCard extends Vue {
  me!: User
  isInProgress!: (key: string, id?: string) => boolean
  canShowMoreBillPayments!: boolean
  visibleBillPayments!: Payment[]

  get isHidden() {
    return this.me.isOnOrdersBilling
  }

  get loading() {
    return this.isInProgress('user/loadMe') || this.isInProgress('orders/loadBillPayments')
  }

  paymentText(payment: Payment) {
    if (payment.type === 'StripeCharge') {
      return this.$t('{amount} charged on {date}', {
        amount: payment.data.displayAmount,
        date: formatDateMedium(payment.data.chargedAt),
      })
    } else if (payment.type === 'StripePayment') {
      return this.$t('{amount} paid on {date}', {
        amount: payment.data.displayAmount,
        date: formatDateMedium(payment.data.debitedAt),
      })
    }

    return this.$t('{amount} paid on {date}', {
      amount: payment.data.displayAmount,
      date: formatDateMedium(payment.data.adjustedAt),
    })
  }

  cardBrand(payment: Payment) {
    if (payment.type !== 'StripeCharge') return null

    return payment.data.cardInfo.brand
  }

  paymentMethodInfo(payment: Payment) {
    if (payment.type === 'StripeCharge') {
      return payment.data.cardInfo
    } else if (payment.type === 'StripePayment') {
      return payment.data.bankAccountInfo
    }

    return null
  }

  showMore() {
    this.$store.commit('orders/showMoreBillPayments')
  }
}
