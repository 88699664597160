









































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { validationStateMixin } from '@/util/validationState'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import User from '@/models/User'
import RadioButtons from '@/components/inputs/RadioButtons.vue'

@Component({
  components: {
    Modal,
    RadioButtons,
  },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  validations: {
    overdueOnly: {
      required,
    },
  },
})
export default class MakePaymentModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  me!: User

  overdueOnly: boolean = false
  error: string = ''

  get paymentOptions() {
    return [
      {
        value: false,
        text: this.$t('Current Balance ({amount})', {
          amount: `$${Number(this.me.account?.currentOwedBalance).toFixed(2)}`,
        }),
      },
      {
        value: true,
        text: this.$t('Overdue Amount ({amount})', {
          amount: `$${Number(this.me.account?.currentOrdersOverdueBalance).toFixed(2)}`,
        }),
      },
    ]
  }

  get loading() {
    return this.isInProgress('user/loadMe') || this.isInProgress('user/makeManualOrderPayment')
  }

  async makePayment() {
    this.$v.$touch()
    if (this.$v.$invalid) return

    await this.$store.dispatch('user/makeManualOrderPayment', {
      overdueOnly: this.overdueOnly,
    })

    const err = this.getError('paymentMethods/makePayment')
    if (err) {
      this.error = err.data
      return
    }

    this.$store.dispatch('showBanner', {
      id: 'payment-submitted-success',
      bgVariant: 'success',
      title: this.$t('Payment submitted'),
      message: this.$t('account.paymentSubmitted.message', { email: this.me.email }),
      durationMs: 5000,
    })

    this.close()
  }

  close() {
    this.$nextTick(() => {
      this.$bvModal.hide('make-order-payment-modal')
    })
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.overdueOnly = false
    this.error = ''
    this.$v.$reset()
  }
}
