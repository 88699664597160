







import { Component, Vue } from 'vue-property-decorator'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'

@Component({
  components: { LinkArrowIcon },
})
export default class ShowMoreLink extends Vue {}
