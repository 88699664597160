


































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Order from '@/models/Order'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import Card from '@/components/Card.vue'
import OrderList from '@/components/OrderList.vue'
import SkeletonListGroup from '@/components/SkeletonListGroup.vue'
import ShowMoreLink from '@/components/ShowMoreLink.vue'

@Component({
  components: {
    Card,
    LinkArrowIcon,
    OrderList,
    SkeletonListGroup,
    ShowMoreLink,
  },
  computed: {
    ...mapGetters('orders', ['pastOrders']),
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class OrdersCardPast extends Vue {
  pastOrders!: Order[]
  isInProgress!: (key: string) => boolean
  getError!: (key: string) => boolean

  loadMore(): void {
    this.$store.dispatch('orders/loadPast.more')
  }

  get hasMore() {
    return this.$store.state.orders.pastOrders.hasNext
  }

  get loadingInitial() {
    return this.isInProgress('orders/loadPast.initial') && !this.pastOrders.length
  }

  get hasError() {
    return !!this.getError('orders/loadPast.initial')
  }
}
