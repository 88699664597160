








import { Component, Vue } from 'vue-property-decorator'

import Card from '@/components/Card.vue'

@Component({
  components: {
    Card,
  },
})
export default class OrdersCardOnboarding extends Vue {}
