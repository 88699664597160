






import { Component, Vue, Prop } from 'vue-property-decorator'
import Order from '@/models/Order'
import OrderListItem from '@/components/OrderListItem.vue'

@Component({
  components: {
    OrderListItem,
  },
})
export default class OrderList extends Vue {
  @Prop({ type: Array, required: true }) orders!: Order[]
}
