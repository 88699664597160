






















































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'

import User from '@/models/User'
import Card from '@/components/Card.vue'
import SkeletonLine from '@/components/SkeletonLine.vue'
import OrderPreferences from '@/models/OrderPreferences'
import PaymentTypeImage from '@/components/PaymentTypeImage.vue'
import PaymentMethodInfo from '@/components/PaymentMethodInfo.vue'
import StripeCard from '@/models/StripeCard'

@Component({
  components: {
    Card,
    PaymentTypeImage,
    PaymentMethodInfo,
    SkeletonLine,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapState('orders', ['preferences']),
    ...mapGetters('paymentMethods', ['fsaHsa']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceededBefore', 'isLoading']),
  },
})
export default class AccountBalanceCard extends Vue {
  me!: User
  preferences!: OrderPreferences
  fsaHsa!: StripeCard[]
  isLoading!: (key: string, id?: string) => boolean
  isInProgress!: (key: string, id?: string) => boolean
  hasSucceededBefore!: (key: string, id?: string) => boolean

  get defaultPaymentMethod() {
    return this.preferences?.defaultPaymentMethod
  }

  get defaultFsaHsaPaymentMethod() {
    return this.preferences?.defaultFsaHsaPaymentMethod
  }

  get currentBalance() {
    if (!this.me.isOnOrdersBilling) {
      return this.me.account?.currentBalance || '0.0'
    }
    return this.me.account?.currentOwedBalance || '0.0'
  }

  get hasBalance() {
    return Number(this.currentBalance) > 0
  }

  get loading() {
    return this.isLoading('user/loadMe') || this.isLoading('orders/loadPreferences')
  }

  get autoPayEnabled() {
    return this.me.account?.autopay
  }

  turnOnAutopay() {
    this.$store.dispatch('user/turnOnAutopay')
  }

  get paymentButtonIsLoading() {
    return !!(
      this.isInProgress('user/makeManualOrderPayment') || this.isInProgress('paymentMethods/load')
    )
  }

  get hasMadeEquiscriptPayment() {
    return (
      this.me.cohorts?.equiscript &&
      (this.hasSucceededBefore('user/makeManualPartialPayment') ||
        this.me.account?.hasPendingCollectionPayment)
    )
  }

  get hasFsaBalanceAndCard() {
    const account = this.me.account

    return !!(
      account?.nonFsaEligBalance &&
      account?.fsaEligibleBalance &&
      this.fsaHsa &&
      this.fsaHsa.length
    )
  }

  get creditBalance() {
    const amount = Number(this.me.account?.creditBalance).toFixed(2)

    return `$${amount}`
  }

  get isOverdue() {
    return this.me.account?.overdue
  }

  get hasCreditBalance() {
    return Number(this.me.account?.creditBalance) > 0
  }

  get isEquiscriptCohort() {
    return !!this.me.cohorts?.equiscript
  }

  async makePayment() {
    await this.$store.dispatch('paymentMethods/load')

    const canMakePartialPayment = !this.me.isOnOrdersBilling || this.isEquiscriptCohort
    if (canMakePartialPayment && this.hasFsaBalanceAndCard) {
      this.$bvModal.show('make-multi-payment-modal')
    } else if (canMakePartialPayment) {
      this.$bvModal.show('make-payment-modal')
    } else if (this.me.canPayOnlyOverdueBalance) {
      this.$bvModal.show('make-order-payment-modal')
    } else {
      this.$store.dispatch('user/makeManualOrderPayment', { overdueOnly: false })
    }
  }
}
