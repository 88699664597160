



























import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import User from '@/models/User'
import PageHeader from '@/components/PageHeader.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AccountBalanceCard from '@/components/account/AccountBalanceCard.vue'
import BillPaymentsCard from '@/components/account/BillPaymentsCard.vue'
import OrdersCardPast from '@/components/OrdersCardPast.vue'
import OrdersCardCurrent from '@/components/OrdersCardCurrent.vue'
import OrdersCardOnboarding from '@/components/OrdersCardOnboarding.vue'
import OrderPreferencesCard from '@/components/OrderPreferencesCard.vue'
import MakePaymentModal from '@/components/account/MakePaymentModal.vue'
import MakeMultiPaymentModal from '@/components/account/MakeMultiPaymentModal.vue'
import MakeOrderPaymentModal from '@/components/account/MakeOrderPaymentModal.vue'

@Component({
  components: {
    AccountBalanceCard,
    BillPaymentsCard,
    PageHeader,
    LoadingSpinner,
    OrdersCardCurrent,
    OrdersCardPast,
    OrdersCardOnboarding,
    OrderPreferencesCard,
    MakePaymentModal,
    MakeMultiPaymentModal,
    MakeOrderPaymentModal,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class OrdersView extends Vue {
  me!: User
  isInProgress!: (key: string) => boolean

  get loadingUser() {
    return this.isInProgress('user/loadMe')
  }

  created(): void {
    this.$store.dispatch('user/ensureMe')
    this.$store.dispatch('orders/loadCurrent')
    this.$store.dispatch('orders/loadPreferences')
    this.$store.dispatch('orders/loadPast.initial')
    this.$store.dispatch('orders/loadBillPayments')
  }
}
