import { render, staticRenderFns } from "./OrdersCardPast.vue?vue&type=template&id=a6d3870e&scoped=true&"
import script from "./OrdersCardPast.vue?vue&type=script&lang=ts&"
export * from "./OrdersCardPast.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6d3870e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCardBody } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
installComponents(component, {BCardBody,BCardTitle})
